import React, { useEffect, useState } from 'react'
import { aboutUs, data } from './data'
import './Team.scss';

export default function TeamCard() {
    const [isMobile, SetIsMobile] = useState(false);
    //ToDo: update content of about us and team card
    useEffect(() => {

        SetIsMobile(document.documentElement.clientWidth <= 450);
        // const colors = ["#3CC157", "#2AA7FF", "#1B1B1B", "#FCBC0F", "#F85F36"];

        // const numBalls = 30;
        // const balls = [];

        // let icons = document.getElementById('icons');

        // for (let i = 0; i < numBalls; i++) {
        //     let ball = document.createElement("div");
        //     ball.classList.add("ball");
        //     ball.style.background = colors[Math.floor(Math.random() * colors.length)];
        //     ball.style.left = `${Math.floor(Math.random() * 98)}vw`;
        //     ball.style.top = `${Math.floor(Math.random() * 70)}vh`;
        //     ball.style.transform = `scale(${Math.random()})`;
        //     ball.style.width = `${Math.random()}em`;
        //     ball.style.height = ball.style.width;

        //     balls.push(ball);
        //     icons?.appendChild(ball);
        // }

        // // Keyframes
        // balls.forEach((el, i, ra) => {
        //     let to = {
        //         x: Math.random() * (i % 2 === 0 ? -11 : 11),
        //         y: Math.random() * 12
        //     };
        //     if (parseInt(el.style.left) > 80) {
        //         if (to.x > 0)
        //             to.x = 2
        //         else
        //             to.x = -2
        //     }
        //     if (parseInt(el.style.top) > 60) {
        //         if (to.y > 0)
        //             to.y = 2
        //         else
        //             to.y = -2
        //     }
        //     let anim = el.animate(
        //         [
        //             { transform: "translate(0, 0)" },
        //             { transform: `translate(${to.x}rem, ${to.y}rem)` }
        //         ],
        //         {
        //             duration: (Math.random() + 1) * 2000, // random duration
        //             direction: "alternate",
        //             fill: "both",
        //             iterations: Infinity,
        //             easing: "ease-in-out"
        //         }
        //     );
        // });

    });
    return (
        <>
            <div className='team main pt-88' id='team'>
                {/* <div id="icons">
                </div> */}
                <div className='container d-flex align-items-center justify-content-center'>
                    <div className='row'>
                        <h1 className='about-us-title'>About Us</h1>
                        {isMobile ?
                            <>
                                <div className="collapse" id="collapseAboutUsText" aria-expanded="false">
                                    {aboutUs.map((a, index) => {
                                        return (<p key={index}>{a}</p>);
                                    })}
                                </div>
                                <button
                                    className="btn collapse-btn collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseAboutUsText"
                                    aria-controls="collapseAboutUsText"
                                >
                                    </button>
                            </>
                            :
                            aboutUs.map((a, index) => {
                                return (<p key={index}>{a}</p>);
                            })
                        }
                        <div className='container-fluid'>
                            <h1 className='mx-auto px-4'>Company Founders</h1>
                            <div className="row">
                                {data.map((d) => {
                                    return (
                                        <div className='col-10 col-md-3 m-auto' key={d.id}>
                                            <article className="card">
                                                <div className="thumb" style={{ background: `url(${d.img}) no-repeat ${d.imgPos}` }}></div>
                                                <div className="infos">
                                                    <p className="title">{d.title}</p>
                                                    <p className="desig">{d.desig}</p>
                                                </div>
                                            </article>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </>
    )
}
