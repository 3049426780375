import React, { useEffect, useState } from 'react';
import './whyus2.scss';

//ToDo: remove
export default function WhyUsSec2() {
    const [windowDimenion, detectHW] = useState({
        winWidth: window.innerWidth,
        winHeight: window.innerHeight,
    })

    const detectSize = () => {
        detectHW({
            winWidth: window.innerWidth,
            winHeight: window.innerHeight,
        })
    }

    useEffect(() => {
        window.addEventListener('resize', detectSize)

        return () => {
            window.removeEventListener('resize', detectSize)
        }
    }, [windowDimenion])
    const rain = () => {
        let rain = document.getElementById("rain");
        let drop = document.getElementsByClassName("drop");
        if (rain && !drop.length) {
            let increment = 0;
            // let drops = "";
            let index = 0;
            let transy = 41;
            if (windowDimenion.winWidth < 767) {
                transy = 48;
            }
            let umberallaSVG = document.getElementById("umberallaSVG");
            let umberallaWidth = umberallaSVG?.clientWidth;
            if (!umberallaWidth) {
                umberallaWidth = 0;
            }
            let leftIncremeent = (((windowDimenion.winWidth - umberallaWidth + 100) / 2) / windowDimenion.winWidth) * 100;
            if (windowDimenion.winWidth < 767) {
                leftIncremeent = (((windowDimenion.winWidth - umberallaWidth + 50) / 2) / windowDimenion.winWidth) * 100;
            }
            const rightIncrement = 95 - leftIncremeent;
            while (increment < 100) {
                let randoHundo = (Math.floor(Math.random() * (98) + 1));
                let randoFiver = (Math.floor(Math.random() * (4 - 1) + 2));
                increment += randoFiver;
                if (increment > 95) break;
                let drop = document.createElement("div");
                drop.classList.add("drop");
                // let setDropClass = false;
                // if (windowDimenion.winWidth >= 2000 && increment > leftIncremeent && increment < rightIncrement) {
                //     setDropClass = true;
                // }
                // else if (windowDimenion.winWidth > 767 && windowDimenion.winWidth < 2000 && increment > 15 && increment < 81) {
                //     setDropClass = true;
                // }
                if (increment > leftIncremeent && increment < rightIncrement) {
                    const umberallaDropAnimationTag = document.getElementById("umberallaDropAni" + index);
                    if (!umberallaDropAnimationTag) {
                        var umberallaDropAni = document.createElement('style');
                        umberallaDropAni.type = 'text/css';
                        umberallaDropAni.id = "umberallaDropAni" + index;
                        if (windowDimenion.winWidth < 767) {
                            if (increment < 48) {
                                transy -= 0.5;
                            }
                            else {
                                transy += 0.5;
                            }
                        }
                        else {
                            if (increment < 48) {
                                transy -= 1;
                            }
                            else {
                                transy += 1;
                            }
                        }

                        let rules = document.createTextNode('@-webkit-keyframes umberallaDropAni' + index + '{' +
                            '0%{transform: translateY(0vh);}' +
                            '75%{transform: translateY(' + transy + 'vh);}' +
                            '100%{transform: translateY(' + transy + 'vh);}');
                        umberallaDropAni.appendChild(rules);
                        document.getElementsByTagName("head")[0].appendChild(umberallaDropAni);
                    }
                    const umberallaDropTag = document.getElementById("umberallaDrop" + index);
                    if (!umberallaDropTag) {
                        var umberallaDrop = document.createElement('style');
                        umberallaDrop.type = 'text/css';
                        umberallaDrop.id = "umberallaDrop" + index;
                        let rules = document.createTextNode('.umberallaDrop' + index + '{animation-name: umberallaDropAni' + index + ' !important;}');
                        umberallaDrop.appendChild(rules);
                        document.getElementsByTagName("head")[0].appendChild(umberallaDrop);
                        drop.classList.add('umberallaDrop' + index);
                    }
                }
                drop.style.left = increment + "%";
                drop.style.bottom = (randoFiver + randoFiver - 1 + 100) + '%';
                drop.style.animationDelay = '0.' + randoHundo + 's';
                drop.style.animationDuration = '0.5' + randoHundo + 's';

                let stem = document.createElement("div");
                stem.classList.add("stem");
                stem.style.animationDelay = '0.' + randoHundo + 's';
                stem.style.animationDuration = '0.5' + randoHundo + 's';
                drop.appendChild(stem);

                let splat = document.createElement("div");
                splat.classList.add("splat");
                splat.style.animationDelay = '0.' + randoHundo + 's';
                splat.style.animationDuration = '0.5' + randoHundo + 's';
                drop.appendChild(splat);

                rain.appendChild(drop);

                index++;

                // drops += '<div class="drop" style="left: ' + increment + '%; bottom: ' 
                // + (randoFiver + randoFiver - 1 + 100) +
                //  '%; animation-delay: 0.' + randoHundo 
                //  + 's; animation-duration: 0.5' + randoHundo +
                //   's;"><div class="stem" style="animation-delay: 0.' + randoHundo + 's; animation-duration: 0.5' + 
                //   randoHundo + 's;"></div><div class="splat" style="animation-delay: 0.' + randoHundo + 's; animation-duration: 0.5' + randoHundo + 
                //   's;"></div></div>';
            }
            // rain.append(drops);
        }
    }

    const wind = () => {
        let transfromOrigin = -23;
        for (let i = 1; i <= 9; i++) {
            let path = document.getElementById("path" + i);
            let text = document.getElementById("text" + i);
            if (path && text) {
                const windAnimationTag = document.getElementById("windAni" + i);
                if (!windAnimationTag) {
                    var windAni = document.createElement('style');
                    windAni.type = 'text/css';
                    windAni.id = "windAni" + i;
                    if (i === 2) {
                        transfromOrigin = -13;
                    }
                    if (i === 3) {
                        transfromOrigin = -2;
                    }
                    if (i === 4) {
                        transfromOrigin = 8;
                    }
                    if (i > 4 && i !== 7) {
                        transfromOrigin += 11;
                    }
                    if (i === 7) {
                        transfromOrigin += 9;
                    }
                    let rules = document.createTextNode('@-webkit-keyframes windAni' + i + '{' +
                        '0%{transform: rotate(5deg); transform-origin: ' + transfromOrigin + '% 43%;}' +
                        '50%{transform: rotate(-5deg);}' +
                        '100%{transform: rotate(5deg); transform-origin:' + transfromOrigin + '% 43%;}');
                    windAni.appendChild(rules);
                    document.getElementsByTagName("head")[0].appendChild(windAni);


                }
                const windclassTag = document.getElementById("windclass" + i);
                if (!windclassTag) {
                    var windclass = document.createElement('style');
                    windclass.type = 'text/css';
                    windclass.id = "windclass" + i;
                    let rules = document.createTextNode('.wind' + i + '{animation: windAni' + i + ' 7s ease-in infinite both;}');
                    windclass.appendChild(rules);
                    document.getElementsByTagName("head")[0].appendChild(windclass);
                    path.classList.add('wind' + i);
                    text.classList.add('wind' + i);
                }
            }
        }
    }

    useEffect(() => {
        // window.onload = () => {
        rain();
        wind();
        // };
    });

    return (
        <div className='container whyus main pt-88' id='whyus'>
            {/* <div id="rain"></div> */}
            <div className='row'>
                <div>
                    <h1 className='mx-auto px-4'>Why Choose US?</h1>
                    <svg version="1.1" id="umberallaSVG"
                        style={{ overflow: "overlay" }} viewBox="-440 0 1400 600" preserveAspectRatio="xMinYMin meet">

                        {/* viewbox dunamically
                            increase umberalla width */}
                        <g>
                            {/* Umberlla handle */}
                            <path fill='#6B6B6B' d="M317.688,504.123h-47.529c-23.584,0-42.772-19.188-42.772-42.772V193.662 c0-7.877,6.388-14.265,14.249-14.265c7.877,0,14.265,6.388,14.265,14.265v267.689c0,7.861,6.396,14.265,14.265,14.265h47.521 c7.861,0,14.249-6.396,14.249-14.265c0-7.877,6.388-14.265,14.265-14.265s14.265,6.388,14.265,14.265 C370.452,484.935,351.271,504.123,327.688,504.123z" />
                            {/* Umberlla Handle shadow */}
                            <path fill="#3C3C3C" d="M255.901,322.371V193.662c0-7.877-6.388-14.265-14.265-14.265c-7.861,0-14.249,6.388-14.249,14.265
	                                                v128.709H265.901z"/>
                            {/* Umberlla tip */}
                            {/* <path fill='#8E8D8D' d="M260.947,64.551c0,4.923-3.986,1.969-8.909,1.969l0,0c-4.923,0-8.909,2.954-8.909-1.969V8.909
	c0-4.923,3.986-8.909,8.909-8.909l0,0c4.923,0,8.909,3.986,8.909,8.909V64.551z"/> */}
                            {/* Umberlla */}
                            <path fill='#111111' d="M -300 258 
                                                    c 154 -316 812 -321 1084 1 
                                                    c -22 -26 -106 -29.812 -135.5 0 
                                                    c -22 -26 -106 -29.812 -135.5 0 
                                                    c -22 -26 -106 -29.812 -135.5 0 
                                                    c -22 -26 -106 -29.812 -135.5 0 
                                                    c -22 -26 -106 -29.812 -135.5 0 
                                                    c -22 -26 -106 -29.812 -135.5 0 
                                                    c -22 -26 -106 -29.812 -135.5 0 
                                                    c -22 -26 -106 -29.812 -135.5 0 "/>

                            {/* Umberlla shadow */}
                            <path fill='#000000' d="M -300 258 
                                                    c 154 -276 812 -281 1084 1 
                                                    c -22 -26 -106 -29.812 -135.5 0 
                                                    c -22 -26 -106 -29.812 -135.5 0 
                                                    c -22 -26 -106 -29.812 -135.5 0 
                                                    c -22 -26 -106 -29.812 -135.5 0 
                                                    c -22 -26 -106 -29.812 -135.5 0 
                                                    c -22 -26 -106 -29.812 -135.5 0 
                                                    c -22 -26 -106 -29.812 -135.5 0 
                                                    c -22 -26 -106 -29.812 -135.5 0 "/>

                            {/* Umberlla horizontal line */}
                            <path fill='#333333' d="M -29 260 
                                                    c 79 -365 438 -273 543 -1 
                                                    c -22 -26 -106 -29.812 -135.5 0 
                                                    c -22 -26 -106 -29.812 -135.5 0 
                                                    c -22 -26 -106 -29.812 -135.5 0 
                                                    c -22 -26 -106 -29.812 -135.5 0 " />

                            {/* Umberlla horizontal line shadow*/}
                            <path fill='#1C1C1C' d="M -29 260 
                                                    c 8 -33 32 -148 120 -204 
                                                    c 77 -11 182 -7 260 5 
                                                    c 54 26 141 127 162 199 
                                                    c -22 -26 -106 -29.812 -135.5 0 
                                                    c -22 -26 -106 -29.812 -135.5 0 
                                                    c -22 -26 -106 -29.812 -135.5 0 
                                                    c -22 -26 -106 -29.812 -135.5 0" />
                        </g>
                        {/* <text x="50" y="35" stroke='#FFCB9A' fill='#FFCB9A' fontSize={46}>Why Choose US?</text> */}
                        <g>
                            <path d='m-300,260 v120' id='path1' stroke='orange' strokeWidth={2} className='wind' />
                            <text y="410" id='text1' stroke="orange" fill="orange" dy="0" fontSize={32} className='wind'>
                                <tspan x="-340" dy="0">
                                    High
                                </tspan>
                                <tspan x="-400" dy="40">
                                    Performance
                                </tspan>
                            </text>
                            <path d='m-165,260 v20' id='path2' stroke='orange' strokeWidth={2} className="wind2" />
                            <text y="310" id='text2' stroke="orange" fill="orange" dy="0" fontSize={32} className="wind2">
                                <tspan x="-190" dy="0">
                                    Free
                                </tspan>
                                <tspan x="-215" dy="40">
                                    Hosting
                                </tspan>
                            </text>
                            <path d='m-30,260 v120' id='path3' stroke='orange' strokeWidth={2} className="wind3" />
                            <text y="410" id='text3' stroke="orange" fill="orange" dy="0" fontSize={32} className="wind3">
                                <tspan x="-80" dy="0">
                                    Secure
                                </tspan>
                                <tspan x="-80" dy="40">
                                    Website
                                </tspan>
                            </text>
                            <path d='m106,260 v20' id='path4' stroke='orange' strokeWidth={2} className="wind4" />
                            <text y="310" id='text4' stroke="orange" fill="orange" dy="0" fontSize={32} className="wind4">
                                <tspan x="10" dy="0">
                                    Proffessionals
                                </tspan>
                                <tspan x="50" dy="40">
                                    working
                                </tspan>
                                <tspan x="60" dy="40">
                                    for you
                                </tspan>
                            </text>
                            <path d='m242,260 v120' id='path5' stroke='orange' strokeWidth={2} />
                            <text y="410" id='text5' stroke="orange" fill="orange" dy="0" fontSize={32}>
                                <tspan x="170" dy="0">
                                    Affordable
                                </tspan>
                                <tspan x="190" dy="40">
                                    Pricing
                                </tspan>
                            </text>
                            <path d='m378,260 v30' id='path6' stroke='orange' strokeWidth={2} />
                            <text y="320" id='text6' stroke="orange" fill="orange" dy="0" fontSize={32}>
                                <tspan x="320" dy="0">
                                    Customer
                                </tspan>
                                <tspan x="330" dy="40">
                                    Support
                                </tspan>
                            </text>
                            <path d='m513,260 v110' id='path7' stroke='orange' strokeWidth={2} />
                            <text y="400" id='text7' stroke="orange" fill="orange" dy="0" fontSize={32}>
                                <tspan x="485" dy="0">
                                    Fully
                                </tspan>
                                <tspan x="450" dy="40">
                                    Responsive
                                </tspan>
                            </text>
                            <path d='m648,260 v20' id='path8' stroke='orange' strokeWidth={2} />
                            <text y="310" id='text8' stroke="orange" fill="orange" dy="0" fontSize={32}>
                                <tspan x="620" dy="0">
                                    Self
                                </tspan>
                                <tspan x="610" dy="40">
                                    Created
                                </tspan>
                                <tspan x="610" dy="40">
                                    Content
                                </tspan>
                            </text>
                            <path d='m783,260 v120' id='path9' stroke='orange' strokeWidth={2} />
                            <text y="400" id='text9' stroke="orange" fill="orange" dy="0" fontSize={32}>
                                <tspan x="750" dy="0">
                                    Redesign
                                </tspan>
                                <tspan x="760" dy="40">
                                    Existing
                                </tspan>
                                <tspan x="760" dy="40">
                                    Website
                                </tspan>
                            </text>
                        </g>
                        <text y="500" stroke="#D9B08C" strokeWidth={1} fontSize="42" fill="#D9B08C" dy="0">
                            <tspan x="60" dy="40">
                                To Protect you technically
                            </tspan>
                        </text>
                    </svg>

                </div>
            </div>

        </div>
    )
}
