import one from './1.jpg';
import two from './2.jpg';
import three from './3.jpg';
import four from './4.png';
import prevBtn from './prev.svg';
import prevBtnImg from './prev.png';
import nextBtnImg from './next.png';
import nextBtn from './next.svg';

export const portfolio = {
    one,
    two,
    three,
    four,
    prevBtn,
    nextBtn,
    prevBtnImg,
    nextBtnImg
};