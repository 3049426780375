import React from "react";
import ProposalForm from "../Components/Contact/ProposalForm/ProposalForm";
import TeamCard from "../Components/Team/TeamCard";
import WhyUsSec2 from "../Components/WhyUs/WhyUsSec2";
import "./SingleHomePage.scss";
import Footer from "../Layout/Components/Footer/Footer";
import SinglePageHeader from "../Layout/Components/SinglePageHeader/SinglePageHeader";
import ServiceSection from "../Components/Services/ServiceSection";
import ImageSlider from "../Components/ImageSlider/ImageSlider";
import FloatWhatsAppButtonComponent from "../Layout/Components/FloatWhatsAppButton/FloatWhatsAppButtonComponent";

export default function SingleHomePage() {

  return (
    <>
      <FloatWhatsAppButtonComponent />
      <SinglePageHeader />
      <div id="mainPage">
        <ServiceSection />
        <WhyUsSec2 />
        <ImageSlider />
        <TeamCard />
        <ProposalForm />
      </div>
      <Footer />
    </>

  );
}
