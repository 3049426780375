import React, { Component } from 'react';
import images from './../../Assets/images';
import { ReactComponent as Laptopsvg } from "../../Assets/laptopsvg.svg";
import { ReactComponent as LaptopPlaceholderForSMDevices } from "../../Assets/centerLaptopSVG.svg";
import './ImageSlider.scss';

interface ImageSliderState {
    currentImage: number;
    images: { path: string, href: string }[];
    slideImage: HTMLElement | null;
    slideImageClick: HTMLElement | null;
    laptopImg: string;
    mobileLaptopImg: string;
    imageLoaded: boolean;
}
//ToDo:Add some text to prompt user about clicking the image
export default class ImageSlider extends Component<{}, ImageSliderState> {
    constructor(props: {} | Readonly<{}>) {
        super(props);
        this.switchImage = this.switchImage.bind(this);
        this.state = {
            slideImage: null,
            slideImageClick: null,
            currentImage: 1,
            images: [
                {
                    path: images.one,
                    href: "Groco"
                },//the height of the image should be height/width of screen(laptop where we are showing image)(492.36/726.69) * width
                {
                    path: images.four,
                    href: "Groco"
                },
                {
                    path: images.three,
                    href: "Groco"
                },
            ],
            laptopImg: images.laptop,
            mobileLaptopImg: images.centerLaptop,
            imageLoaded: false
        };
    }
    switchImage() {
        if (this.state.currentImage < this.state.images.length - 1) {
            this.setState({
                currentImage: this.state.currentImage + 1
            });
        } else {
            this.setState({
                currentImage: 0
            });
        }
        this.state.slideImage?.setAttribute("href", this.state.images[this.state.currentImage].path);
        this.state.slideImageClick?.setAttribute("href", this.state.images[this.state.currentImage].href);

    }
    prevImage(context: ImageSlider) {
        if (context.state.currentImage > 0) {
            context.setState({
                currentImage: context.state.currentImage - 1
            });
        } else {
            context.setState({
                currentImage: context.state.images.length - 1
            });
        }
        context.state.slideImage?.setAttribute("href", context.state.images[context.state.currentImage].path);
        context.state.slideImageClick?.setAttribute("href", context.state.images[context.state.currentImage].href);
    }
    nextImage(context: ImageSlider) {
        if (context.state.currentImage < context.state.images.length - 1) {
            context.setState({
                currentImage: context.state.currentImage + 1
            });
        } else {
            context.setState({
                currentImage: 0
            });
        }
        context.state.slideImage?.setAttribute("href", context.state.images[context.state.currentImage].path);
        context.state.slideImageClick?.setAttribute("href", context.state.images[context.state.currentImage].href);
    }

    componentDidMount() {
        if (!this.state.imageLoaded) {
            var laptopImg = document.getElementById("laptopImg");

            if (document.documentElement.clientWidth <= 450) {
                laptopImg?.setAttribute("href", this.state.mobileLaptopImg);
            }
            else {
                laptopImg?.setAttribute("href", this.state.laptopImg);
            }

            var slideimg = document.getElementById("slideImage");
            slideimg?.setAttribute("href", this.state.images[0].path);

            var slideimgClick = document.getElementById("slideImageClick");
            slideimgClick?.setAttribute("href", this.state.images[0].href);

            var prevBtnImg = document.getElementById("prevBtnImg");
            prevBtnImg?.setAttribute("href", images.prevBtnImg);

            var nextBtnImg = document.getElementById("nextBtnImg");
            nextBtnImg?.setAttribute("href", images.nextBtnImg);

            var prevBtn = document.getElementById("prevButton");
            prevBtn?.addEventListener("click", () => this.prevImage(this));

            var nextBtn = document.getElementById("nextButton");
            nextBtn?.addEventListener("click", () => this.nextImage(this));

            this.setState({
                imageLoaded: true,
                slideImage: slideimg,
                slideImageClick: slideimgClick
            });
        }
        setInterval(this.switchImage, 5000);
    }

    openGroco() {
        window.open("./../Assets/groco/index.html");
    }

    render() {
        return (
            <div className="slideshow-container pt-88" id='portfolio'>
                <h1 className='mx-auto px-4'>Portfolios</h1>
                <p className='portfolio-desc px-3'>
                    Our portfolio showcases our expertise in web design, software development, and more. how we've helped businesses like yours succeed with our custom solutions and expert services.
                </p>
                <p className='portfolio-desc portfolio-desc-click px-3'>
                    Click on the below sceen to see our demo website.
                </p>
                {document.documentElement.clientWidth <= 450 ?
                    <LaptopPlaceholderForSMDevices />
                    :
                    <Laptopsvg />
                }
            </div>
        );
    }

}