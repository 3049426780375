import angular from './angular.png';
import css from './css.jpg';
import html from './html.png';
import js from './js.jpg';
import net from './net.png';
import node from './node.png';
import react from './react.png';
import tickMark from './tickMark.png';
import crossMark from "./crossMark.png";

export const icons = {
    angular,
    css,
    html,
    js,
    net,
    node,
    react,
    tickMark,
    crossMark
};