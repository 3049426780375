import React, { useEffect, useState } from 'react';
import './ServiceSection.scss';
import anime from 'animejs/lib/anime.es.js';
import GridComp from '../GridComp/Grid';
import { servicesData } from './data';

export default function ServiceSection() {
    let startAnimation = true;
    const enum CardKey {
        SoftwareDevelopment = "SoftwareDevelopment",
        WebsiteDevelopment = "WebsiteDevelopment",
        WebsiteManagement = "WebsiteManagement"
    }
    let cardAnimation: { [id: string]: anime.AnimeInstance } = {};
    //     [CardKey.SoftwareDevelopment]: initializeAnimation(CardKey.SoftwareDevelopment),
    //     [CardKey.WebsiteDevelopment]: initializeAnimation(CardKey.WebsiteDevelopment),
    //     [CardKey.WebsiteManagement]: initializeAnimation(CardKey.WebsiteManagement)
    // };

    const toggleAnimationDirecttion = (direction: string): string => {
        if (direction === "normal")
            return "reverse";
        else
            return "normal";
    }

    const Animate = (cardKey: CardKey) => {
        if (startAnimation) {
            playAnimate(cardKey);
            const card = document.getElementById(cardKey);
            if (card)
                card.style.backgroundPosition = "right bottom";
        }
        else {
            reverseAnimate(cardKey);
            const card = document.getElementById(cardKey);
            if (card)
                card.style.backgroundPosition = "left bottom";
        }
        startAnimation = !startAnimation;
    }

    const playAnimate = (cardKey: CardKey) => {
        if (!cardAnimation[cardKey]) {
            cardAnimation[cardKey] = initializeAnimation(cardKey);
        }
        else {
            cardAnimation[cardKey].direction = toggleAnimationDirecttion(cardAnimation[cardKey].direction);
        }
        cardAnimation[cardKey].play();
    }
    const reverseAnimate = (cardKey: CardKey) => {
        if (cardAnimation[cardKey].completed) {
            cardAnimation[cardKey].direction = toggleAnimationDirecttion(cardAnimation[cardKey].direction);
            cardAnimation[cardKey].play();
        }
        else
            cardAnimation[cardKey].reverse();
    }

    function initializeAnimation(cardKey: CardKey): anime.AnimeInstance {
        switch (cardKey) {
            case CardKey.SoftwareDevelopment:
                return (anime({
                    targets: '.grid .slideLeft',
                    scale: [
                        { value: .1, easing: 'easeOutSine', duration: 500 },
                        { value: 1, easing: 'easeInOutQuad', duration: 500 }
                    ],
                    delay: anime.stagger(80, { grid: [15, 1], from: 'first' }),
                    direction: "reverse"
                }));
            case CardKey.WebsiteDevelopment:
                return (anime({
                    targets: '.grid .wave',
                    translateY: [
                        { value: -30, easing: 'easeOutSine', duration: 500 },
                        { value: 0, easing: 'easeInOutQuad', duration: 500 }
                    ],
                    delay: anime.stagger(80, { grid: [15, 5], from: 'first' }),
                    direction: "reverse"
                }));
            case CardKey.WebsiteManagement:
                return (anime({
                    targets: '.grid .break',
                    translateX: anime.stagger(5, { grid: [15, 5], from: 'center', axis: 'x' }),
                    translateY: anime.stagger(5, { grid: [15, 5], from: 'center', axis: 'y' }),
                    rotateZ: anime.stagger([0, 90], { grid: [15, 5], from: 'center', axis: 'x' }),
                    delay: anime.stagger(80, { grid: [15, 5], from: 'last' }),
                    easing: 'easeInOutQuad',
                    direction: "normal"
                }));
            default:
                return (anime({
                    targets: '.grid .el',
                    scale: [
                        { value: .1, easing: 'easeOutSine', duration: 500 },
                        { value: 1, easing: 'easeInOutQuad', duration: 500 }
                    ],
                    delay: anime.stagger(80, { grid: [15, 5], from: 'first' }),
                    direction: "reverse"
                }));
        }
    }
    if (document.documentElement.clientWidth <= 450) {
        return (
            <div className='container' id='services'>
                <h1 className='mx-auto px-4' id='servHead'>
                    Services
                </h1>
                <div className="row">
                    <div className="col-12 col-md-6 card-center">
                        <div className="card mx-auto"
                            id={CardKey.SoftwareDevelopment}
                            onClick={() => Animate(CardKey.SoftwareDevelopment)}>
                            <div id='animate'>
                                <GridComp class="slideLeft circle circle-hollow" />
                            </div>
                            <p className="heading">{servicesData[0].heading}</p>
                            <p>{servicesData[0].desc}</p>
                            <div className="desc" >
                                <ul>
                                    {servicesData[0].products && servicesData[0].products.map((p, i) => {
                                        return <li key={i}>{p}</li>
                                    })}
                                </ul>
                            </div>
                            <div id='animate'>
                                <GridComp class="slideLeft circle circle-hollow" />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="card mx-auto"
                            id={CardKey.WebsiteDevelopment}
                            onClick={() => Animate(CardKey.WebsiteDevelopment)}>
                            <div id='animateWD'>
                                <GridComp class="circle wave" />
                            </div>
                            <p className="heading">{servicesData[1].heading}</p>
                            <p>{servicesData[1].desc}</p>
                            <div className="desc" >
                                <ul>
                                    {servicesData[1].products && servicesData[1].products.map((p, i) => {
                                        return <li key={i}>{p}</li>
                                    })}
                                </ul>
                            </div>
                            <div id='animateWD'>
                                <GridComp class="circle wave" />
                            </div>
                        </div>
                        <div className="card mx-auto"
                            id={CardKey.WebsiteManagement}
                            onClick={() => Animate(CardKey.WebsiteManagement)}>
                            <div id='animateWM'>
                                <GridComp class="square break" />
                            </div>
                            <p className="heading">{servicesData[2].heading}</p>
                            <p>{servicesData[2].desc}</p>
                            <div className="desc" >
                                <ul>
                                    {servicesData[2].products && servicesData[2].products.map((p, i) => {
                                        return <li key={i}>{p}</li>
                                    })}
                                </ul>
                            </div>
                            <div id='animateWM'>
                                <GridComp class="square break" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    else {
        return (
            <div className='container' id='services'>
                <h1 className='mx-auto px-4' id='servHead'>
                    Services
                </h1>
                <div className="row">
                    <div className="col-12 col-md-6 card-center">
                        <div className="card mx-auto"
                            onMouseEnter={() => playAnimate(CardKey.SoftwareDevelopment)}
                            onMouseLeave={() => reverseAnimate(CardKey.SoftwareDevelopment)}>
                            <div >
                                <GridComp class="slideLeft circle circle-hollow" />
                            </div>
                            <p className="heading">{servicesData[0].heading}</p>
                            <p>{servicesData[0].desc}</p>
                            <div className="desc" >
                                <ul>
                                    {servicesData[0].products && servicesData[0].products.map((p, i) => {
                                        return <li key={i}>{p}</li>
                                    })}
                                </ul>
                            </div>
                            <div >
                                <GridComp class="slideLeft circle circle-hollow" />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="card mx-auto"
                            onMouseEnter={() => playAnimate(CardKey.WebsiteDevelopment)}
                            onMouseLeave={() => reverseAnimate(CardKey.WebsiteDevelopment)}>
                            <div id='animateWD'>
                                <GridComp class="circle wave" />
                            </div>
                            <p className="heading">{servicesData[1].heading}</p>
                            <p>{servicesData[1].desc}</p>
                            <div className="desc" >
                                <ul>
                                    {servicesData[1].products && servicesData[1].products.map((p, i) => {
                                        return <li key={i}>{p}</li>
                                    })}
                                </ul>
                            </div>
                            <div id='animateWD'>
                                <GridComp class="circle wave" />
                            </div>
                        </div>
                        <div className="card mx-auto"
                            onMouseEnter={() => playAnimate(CardKey.WebsiteManagement)}
                            onMouseLeave={() => reverseAnimate(CardKey.WebsiteManagement)}>
                            <div id='animateWM'>
                                <GridComp class="square break" />
                            </div>
                            <p className="heading">{servicesData[2].heading}</p>
                            <p>{servicesData[2].desc}</p>
                            <div className="desc" >
                                <ul>
                                    {servicesData[2].products && servicesData[2].products.map((p, i) => {
                                        return <li key={i}>{p}</li>
                                    })}
                                </ul>
                            </div>
                            <div id='animateWM'>
                                <GridComp class="square break" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            // <>ToDo: later stages use on hove background change like below
            //     <section id="home__capabilities">
            //         <img className="fill-background default" alt="A surfer figure in the haze" src="https://rno1.com/assets/images/home/capabilities-default.jpg" />

            //         <div className="container">
            //             <span className="up" data-reveal="fade-up" >We specialize in</span>

            //             <div className="capabilities__list">
            //                 <a href="https://rno1.com/capabilities" data-capability="0" data-reveal="fade-up">
            //                     Branding &amp; Identity          <span></span></a>
            //                 <a href="https://rno1.com/capabilities" data-capability="1" data-reveal="fade-up">
            //                     Websites &amp; Digital Platforms          <span></span></a>
            //                 <a href="https://rno1.com/capabilities" data-capability="2" data-reveal="fade-up">
            //                     Web3 Experience Design          <span></span></a>
            //                 <a href="https://rno1.com/capabilities" data-capability="3" data-reveal="fade-up">
            //                     eCommerce Experiences          <span></span></a>
            //                 <a href="https://rno1.com/capabilities" data-capability="4" data-reveal="fade-up">
            //                     VR &amp; AR Environments          <span></span></a>
            //             </div>
            //         </div>
            //         <div className="background__images">
            //             <img src="https://rno1.com/media/pages/capabilities/branding-identity/349589516d-1646240653/capabilities-branding-r-1920x994.jpg" alt="Branding &amp; Identity" data-capability="0" className="" />
            //             <img src="https://rno1.com/media/pages/capabilities/websites-digital-platforms/161a12a9b0-1646240653/capabilities-web-r-1920x994.jpg" alt="Websites &amp; Digital Platforms" data-capability="1" className="" />
            //             <img src="https://rno1.com/media/pages/capabilities/web3-experience-design/d375fbf443-1649850640/capabilities-web3-r-1920x994.jpg" alt="" data-capability="2" className="" />
            //             <img src="https://rno1.com/media/pages/capabilities/ecommerce-experiences/7bec86d4d2-1646240653/capabilities-e-comm-r-1920x994.jpg" alt="eCommerce Experiences" data-capability="3" className="" />
            //             <img src="https://rno1.com/media/pages/capabilities/vr-ar-environments/8a744b05a5-1646240653/capabilities-vr-r-1920x994.jpg" alt="VR &amp; AR Environments" data-capability="4" className="" />
            //         </div>
            //     </section>
            // </>
        )
    }
}