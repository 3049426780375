import "./App.scss";
//import bootstrap for all js functions from bootsrtap to be used
import 'bootstrap';
import SingleHomePage from "./Pages/SingleHomePage";
function App() {
  return (
    <div>
      <SingleHomePage />
    </div>
  );
}

export default App;
