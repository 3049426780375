import { v4 as uuidv4 } from "uuid";
import images from '../../Assets/images';

export const data = [
    {
        id: uuidv4(),
        img: images.TeamAditi,
        title: `Aditi Hooda`,
        desig: `CEO, Founder`,
        imgPos:'center'
    },
    {
        id: uuidv4(),
        img: images.rishabh,
        title: `Rishabh Gupta`,
        desig: `Director, Founder`,
        imgPos:'top'
    }
];
export const aboutUs=[`Welcome to Techiera, the leading website development company! We specialize in creating stunning, responsive websites that are tailored to your unique needs.`,
`Our team of experienced developers and designers can help you create a website that not only looks great, but also functions flawlessly. From simple one-page sites to complex e-commerce platforms, we have the expertise to bring your vision to life. We understand that your website is the face of your business online, and that's why we make sure that it is optimized for search engines. Our websites are built with clean, efficient code that is easy for search engines to crawl and index, helping you rank higher in search results. We also make sure that your website is user-friendly and easy to navigate, so that your visitors can find what they need quickly and easily. With our responsive design, your website will look great on any device, whether it's a desktop computer, a tablet, or a smartphone.`,
`At Techiera, we believe in providing our clients with the best possible service. That's why we offer a 100% satisfaction guarantee on all of our projects. We're confident that you'll be happy with the website we create for you, but if for any reason you're not satisfied, we'll work with you until you are. If you're looking for a reliable and experienced website development company for your business, look no further than Techiera. Contact us today to schedule a consultation and let us help you take your business to the next level!`
]