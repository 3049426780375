import './floatWhatsaAppBtn.scss';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
export default function FloatWhatsAppButtonComponent() {
  return (
    <div className="float-whatsapp">
      <a href="https://wa.me/+919540928272" target="_blank">
        <FontAwesomeIcon icon={faWhatsapp} className='fa-whatsapp'/>
      </a>
    </div>
  );
}
