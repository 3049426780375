import React, { MouseEventHandler, useEffect, useState } from "react";
import images from "../../../Assets/images";
import "./header.scss";

const SinglePageHeader = () => {
  //ToDo: remove first if and check code in refactoring, There was a gitch after removing the first if check to verify again.
  //ToDo: fix ref not working on header click
  //ToDo:menu not closing on item click
  // let headerRef: React.RefObject<HTMLInputElement>;
  // headerRef = React.createRef();

  let logo: HTMLElement | null;
  let header: HTMLElement | null;
  let logoimg: HTMLElement | null;
  const [browserSize, setbrowserSize] = useState(
    document.documentElement.clientWidth
  );
  useEffect(() => {
    const handleResize = () => {
      setbrowserSize(document.documentElement.clientWidth);
    };
    // font-size: 18px;
    // height: 35px;
    // padding-top: 4px;
    const handleScroll = (e: any) => {
      if (!logo) logo = document.getElementById("logo");
      if (!logoimg) logoimg = document.getElementById("logo-img");
      if (!header) header = document.getElementById("header");
      let fontSizeStartingValue = 90;
      let fontSizeEndingValue = 30;
      let heightStartingValue = 392;
      let heightEndingValue = 50;
      let logoImgHeightStartingValue = 150;
      let logoImgHeightEndingValue = 50;
      let paddingTopStartingValue = 142;
      let paddingTopEndingValue = 0;

      if (document.documentElement.clientWidth < 700) {
        fontSizeStartingValue = 25;
        fontSizeEndingValue = 18;
        heightStartingValue = 298;
        heightEndingValue = 35;
        logoImgHeightStartingValue = 45;
        logoImgHeightEndingValue = 35;
        paddingTopStartingValue = 125;
        paddingTopEndingValue = 4;
      }
      let fontSizePerc = (fontSizeStartingValue - fontSizeEndingValue) / heightStartingValue;//0.153;
      let heightPerc = (heightStartingValue - heightEndingValue) / heightStartingValue;//0.872;
      let logoImgHeightPerc = (logoImgHeightStartingValue - logoImgHeightEndingValue) / heightStartingValue;
      let paddingTopPerc = (paddingTopStartingValue - paddingTopEndingValue) / heightStartingValue;//0.362;

      if (logo) {
        //if we are at the very top of the page scrolled less than 50 then
        //we want the logo to be shown below hence setting the properties to show logo below.
        if (document.documentElement.scrollTop <= 0) {
          logo.style.fontSize = fontSizeStartingValue + "px";
          logo.style.height = heightStartingValue + "px";
          logo.style.paddingTop = paddingTopStartingValue + "px";
          if (logoimg) logoimg.style.height = logoImgHeightStartingValue + "px";
          if (header) {
            header.style.backgroundColor = "rgba(17, 100, 102, 0)";
          }
        } else if (document.documentElement.scrollTop <= heightStartingValue) {
          //if we have scrolled less than or equal to 500 then
          //we are adding animation effect of taking the logo to the header
          var fontSize =
            fontSizeStartingValue - document.documentElement.scrollTop * fontSizePerc; //(90-30)/heightStartingValue
          var height =
            heightStartingValue - document.documentElement.scrollTop * heightPerc; //(heightStartingValue-50)/heightStartingValue
          var logoImgHeight =
            logoImgHeightStartingValue - document.documentElement.scrollTop * logoImgHeightPerc;
          var paddingTop =
            paddingTopStartingValue -
            document.documentElement.scrollTop * paddingTopPerc; //(paddingTopStartingValue-0)/heightStartingValue
          var opacity = 0 + document.documentElement.scrollTop * 0.0025; //(1-0)/heightStartingValue
          logo.style.height = height + "px";
          logo.style.paddingTop = paddingTop + "px";
          logo.style.fontSize = fontSize + "px";
          if (logoimg) logoimg.style.height = logoImgHeight + "px";
          if (header) {
            header.style.backgroundColor =
              "rgba(17, 100, 102, " + opacity + ")";
          }
        } else {
          //if we have scrolled more than 500 then
          //we are setting the properties so that logo is at the top inside the header
          logo.style.fontSize = fontSizeEndingValue + "px";
          logo.style.height = heightEndingValue + "px";
          logo.style.paddingTop = paddingTopEndingValue + "px";
          if (logoimg) logoimg.style.height = logoImgHeightEndingValue + "px";
          if (header) {
            header.style.backgroundColor = "rgba(17, 100, 102, 1)";
          }
        }
      }
    };
    handleScroll(null);
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const closeMenu = () => {
    var offcanvas = document.getElementById("offcanvasNavbar");
    offcanvas?.classList.remove('show');
    offcanvas?.classList.add('hiding');
    setTimeout(() => {
      offcanvas?.classList.remove('hiding');
    }, 500);
    var shadow = document.getElementById("backdrop");
    if (shadow) {
      shadow.className = "";
    }
  }
  const openMenu = () => {
    var offcanvas = document.getElementById("offcanvasNavbar");
    offcanvas?.classList.add('showing');
    setTimeout(() => {
      offcanvas?.classList.remove('showing');
      offcanvas?.classList.add('show');
    }, 500);
    var shadow = document.getElementById("backdrop");
    if (shadow) {
      shadow.className += "backdrop";
    }
  }
  return (
    <>
      <nav
        className="navbar sticky-top navbar-expand-xl"
        id="header"
      // ref={headerRef}
      >
        <div className="container-fluid">
          <div className="row pt-2">
            <div className="col-3 px-0">
              <button
                className="navbar-toggler ms-4"
                type="button"
                onClick={openMenu}
              // data-bs-toggle="offcanvas"
              // data-bs-target="#offcanvasNavbar"
              // aria-controls="offcanvasNavbar"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="offcanvas offcanvas-start"
                tabIndex={-1}
                id="offcanvasNavbar"
                aria-labelledby="offcanvasNavbarLabel"
              >
                <div className="offcanvas-header">
                  <button
                    type="button"
                    className="btn-close text-reset"
                    onClick={closeMenu}
                    // data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="offcanvas-body" id="menuitems">
                  <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                    <li className="nav-item">
                      {/* <button
                        className="btn-dismiss"
                        data-bs-dismiss="offcanvas"
                        onClick={(e) => closeMenu()}
                      > */}
                      <a className="nav-link" href="#services" onClick={closeMenu}>
                        Services
                      </a>
                      {/* </button> */}
                    </li>
                    <li className="nav-item">
                      {/* <button
                        className="btn-dismiss"
                        data-bs-dismiss="offcanvas"
                      > */}
                      <a className="nav-link" href="#whyus" onClick={closeMenu}>
                        WhyUs
                      </a>

                      {/* </button> */}
                    </li>
                    <li className="nav-item">
                      {/* <button
                        className="btn-dismiss"
                        onClick={(e) => closeMenu(e, "#whyus")}
                      > */}
                      <a className="nav-link" href="#portfolio" onClick={closeMenu}>
                        Portfolio
                      </a>
                      {/* </button> */}
                    </li>
                    <li className="nav-item">
                      {/* <button
                        className="btn-dismiss"
                        data-bs-dismiss="offcanvas"
                      > */}
                      <a className="nav-link" href="#team" onClick={closeMenu}>
                        About Us
                      </a>
                      {/* </button> */}
                    </li>
                    {browserSize <= 1200 && (
                      <li className="nav-item">
                        <a className="nav-link" href="#contact" onClick={closeMenu}>
                          Contact Us
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
              <div id="backdrop"></div>
            </div>
            <div className="col-9 col-md-7">
              {/* <img src={images.logo} alt="brandlogo" /> */}
              <div className="logo" id="logo">
                <a href="#" className="nav-link">
                  <img id="logo-img" src={images.svgLogo} />
                  {/* <Logoimg id="logo-img" /> */}
                  Techiera
                </a>
              </div>
            </div>
            {browserSize > 1200 && (
              <div className="col-2">
                <ul className="navbar-nav me-auto">
                  <li className="nav-item">
                    <a className="nav-link" href="#contact">
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </nav>
    </>
  );
};
export default SinglePageHeader;
