import React from 'react';
import './Grid.scss';

export default function GridComp(props: {class: string}) {
    const rows = [];
    for (let i = 0; i < 2; i++) {
        for (let j = 0; j < 15; j++) {
            rows.push(<span key={`${i}${j}`} className={props.class} style={{ transform: "scale(1)" }}></span>);
        }
        rows.push(<br key={`br${i}`}/>);
    }
    return (
        <div className="grid">
            {rows}
        </div>
    )

}