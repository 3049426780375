export const servicesData = [
  {
    heading: "Software Development",
    desc: `We build custom software solutions tailored to your needs, from mobile to desktop programs, using the latest technologies and programming languages for efficient, reliable results, with a focus on quality and customer satisfaction. We deliver products like:-`,
    products: [
      "Windows Software",
      "Mobile Application",
      "CRM",
      "Windows Services",
      "APIs",
      "Hooks",
    ],
  },
  {
    heading: "Website Development",
    desc: `Our website development service creates custom sites with responsive design, SEO optimization, and user-friendly features, helping you stand out in a crowded online marketplace and reach your target audience. We deliver products like:-`,
    products: [
      "Web Design",
      "Static Website",
      "E-commerce Website",
      "Dynamic Website",
      "Landing Pages"
    ],
  },
  {
    heading: "Website Maintenance",
    desc: `Keep your website running smoothly with our comprehensive website maintenance service. Our expert team provides updates, backups, security checks, and support to ensure your site stays secure and up-to-date. We deliver products like:-`,
    products: [
      "Application Upgrade",
      "Content Update",
      "Security Update",
      "Application Support",
      "Software Assistance"
    ],
  },
];
