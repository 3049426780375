import TeamAditi from './TeamAditi.jpeg';
import r1 from './r1.jpeg';
import r2 from './r2.jpeg';
import rishabh from './rishabh.jpeg';

export const team = {
    TeamAditi,
    r1,
    r2,
    rishabh
};