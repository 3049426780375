import React, { Component } from "react";
import PhoneInput, {
  isValidPhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";
import './PorposalForm.scss';
import emailjs from '@emailjs/browser';
import { environment } from "../../../environment/environment.prod";
import Toast from "../../Toast/Toast";
import images from "../../../Assets/images";

const required = (val: string) => !(val && val.length);
const maxLength = (len: number, val: string) => !(!val || val.length <= len);
const minLength = (len: number, val: string) => !(val && val.length >= len);
// const isNumber = (val) => !isNaN(Number(val));
const validEmail = (val: string) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val);

interface IFormState {
  firstname: string;
  lastname: string;
  telnum: string;
  email: string;
  contactType: string;
  message: string;
  toastMessage: {
    id: number,
    title: string,
    description: string,
    backgroundColor: string,
    icon: string
  } | undefined,
  touched: {
    firstname: boolean;
    lastname: boolean;
    telnum: boolean;
    email: boolean;
    message: boolean;
  };
}

export default class ProposalForm extends Component<{}, IFormState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      firstname: "",
      lastname: "",
      telnum: "+91",
      email: "",
      contactType: "tel.",
      message: "",
      toastMessage: undefined,
      touched: {
        firstname: false,
        lastname: false,
        telnum: false,
        email: false,
        message: false,
      },
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  handleInputChange(event: { target: any }) {
    if (event) {
      const target = event.target;
      if (target) {
        const value =
          target.type === "checkbox" ? target.checked : target.value;
        const name: string = target.name;

        this.setState({ ...this.state, [name]: value });
      }
    }
  }

  handleBlur = (field: string) => () => {
    this.setState({
      touched: { ...this.state.touched, [field]: true },
    });
  };

  validate() {
    const errors = {
      firstname: "",
      lastname: "",
      telnum: "",
      email: "",
      message: "",
    };
    if (this.state.touched.firstname) {
      if (required(this.state.firstname)) {
        errors.firstname = "First name is required";
      } else if (minLength(3, this.state.firstname)) {
        errors.firstname = "First name should be >= 3 characters";
      } else if (maxLength(15, this.state.firstname)) {
        errors.firstname = "First name should be <= 15 characters";
      }
    }
    if (this.state.touched.lastname) {
      if (required(this.state.lastname)) {
        errors.lastname = "Last name is required";
      } else if (minLength(3, this.state.lastname)) {
        errors.lastname = "Last name should be >= 3 characters";
      } else if (maxLength(15, this.state.lastname)) {
        errors.lastname = "Last name should be <= 15 characters";
      }
    }
    if (this.state.touched.telnum) {
      if (this.state.telnum) {
        const phoneNumber = parsePhoneNumber(this.state.telnum);
        if (!phoneNumber || required(phoneNumber.nationalNumber)) {
          errors.telnum = "Tel. number is required";
        } else if (!isValidPhoneNumber(this.state.telnum)) {
          errors.telnum = "Tel. Number is not valid";
        }
      } else {
        errors.telnum = "Tel. number is required";
      }
    }
    if (this.state.touched.email) {
      if (required(this.state.email)) {
        errors.email = "Email is required";
      } else if (!validEmail(this.state.email)) {
        errors.email = "Email id is invalid";
      }
    }
    if (this.state.touched.message) {
      if (required(this.state.message)) {
        errors.message = "Message is required";
      }
    }
    return errors;
  }

  submitValidation() {
    this.setState({
      touched: {
        firstname: true,
        lastname: true,
        telnum: true,
        email: true,
        message: false,
      },
    });
  }

  handleSubmit(event: { preventDefault: () => void }) {
    this.submitValidation();
    // alert("Current state is: " + JSON.stringify(this.state));
    if (environment.emailjs.REACT_APP_SERVICE_ID && environment.emailjs.REACT_APP_EMAIL_TEMPLATE_ID) {

      emailjs.send(environment.emailjs.REACT_APP_SERVICE_ID, environment.emailjs.REACT_APP_EMAIL_TEMPLATE_ID, this.state, environment.emailjs.REACT_APP_API_KEY)
        .then((response) => {
          this.setState({
            toastMessage: {
              id: 1,
              title: 'Success',
              description: 'Your message has been sent to our team, We will get back.',
              backgroundColor: '#198754',
              icon: images.tickMark
            }
          });
          setTimeout(() => {
            this.setState({ toastMessage: undefined });
          }, 4000);
          // console.log('SUCCESS!', response.status, response.text);
        }, (err) => {
          this.setState({
            toastMessage: {
              id: 1,
              title: 'Alert',
              description: 'We are not able to reach out to our team at this moment, Please try after sometime.',
              backgroundColor: '#dc3545',
              icon: images.crossMark
            }
          });
          setTimeout(() => {
            this.setState({ toastMessage: undefined });
          }, 4000);
          // console.log('FAILED...', err);
        });
    }
    this.setState({
      firstname: "",
      lastname: "",
      telnum: "+91",
      email: "",
      contactType: "tel.",
      message: "",
      touched: {
        firstname: false,
        lastname: false,
        telnum: false,
        email: false,
        message: false,
      },
    })
    event.preventDefault();
  }

  getElementClass(error: string, isTouched: boolean) {
    return isTouched
      ? error
        ? "form-control is-invalid"
        : "form-control is-valid"
      : "form-control";
  }
  render() {
    var errors = {
      firstname: "",
      lastname: "",
      telnum: "",
      email: "",
      message: "",
    };
    errors = this.validate();

    return (
      <>
        <div className="container-fluid formBG main pt-88" id="contact">
          <div className="row h-100 d-flex align-items-center justify-content-center">
            <div className="col-sm-8 mainFormBG">
              <div className="contact__form">
                <h1 className="section__title"> Request a proposal </h1>
                <form onSubmit={this.handleSubmit}>
                  <div className="form-group row">
                    <div className="col-md-6 mb-4">
                      <label htmlFor="firstname"> First Name </label>
                      <div className="col-md-10">
                        <input
                          type="text"
                          id="firstname"
                          name="firstname"
                          value={this.state.firstname}
                          onBlur={this.handleBlur("firstname")}
                          onChange={this.handleInputChange}
                          className={this.getElementClass(
                            errors.firstname,
                            this.state.touched.firstname
                          )}
                        />
                        <div className="invalid-feedback">
                          {errors.firstname}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 mb-4">
                      <label htmlFor="lastname"> Last Name </label>
                      <div className="col-md-10">
                        <input
                          type="text"
                          id="lastname"
                          name="lastname"
                          value={this.state.lastname}
                          onBlur={this.handleBlur("lastname")}
                          onChange={this.handleInputChange}
                          className={this.getElementClass(
                            errors.lastname,
                            this.state.touched.lastname
                          )}
                        />
                        <div className="invalid-feedback">
                          {errors.lastname}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-md-6 mb-4">
                      <label htmlFor="email"> Email Address </label>
                      <div className="col-md-10">
                        <input
                          type="email"
                          id="email"
                          name="email"
                          value={this.state.email}
                          onBlur={this.handleBlur("email")}
                          onChange={this.handleInputChange}
                          className={this.getElementClass(
                            errors.email,
                            this.state.touched.email
                          )}
                        />
                        <div className="invalid-feedback">{errors.email}</div>
                      </div>
                    </div>
                    <div className="col-md-6 mb-4">
                      <label htmlFor="telnum"> Mobile Number </label>
                      <div className="col-md-10">
                        <PhoneInput
                          international
                          defaultCountry="IN"
                          placeholder="phone number"
                          value={this.state.telnum}
                          onBlur={this.handleBlur("telnum")}
                          onChange={(e) => {
                            if (e) this.setState({ telnum: e.toString() });
                          }}
                          className={this.getElementClass(
                            errors.telnum,
                            this.state.touched.telnum
                          )}
                        />
                        <div className="invalid-feedback">
                          {errors.telnum}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="message"> Write your message here </label>
                    <div className="col-md-11 mb-4">
                      <textarea
                        id="message"
                        name="message"
                        rows={6}
                        value={this.state.message}
                        onBlur={this.handleBlur("message")}
                        onChange={this.handleInputChange}
                        className={this.getElementClass(
                          errors.message,
                          this.state.touched.message
                        )}
                      />
                      <div className="invalid-feedback">{errors.message}</div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-md-10  mb-4">
                      <button
                        type="submit"
                        className={
                          errors.firstname ||
                            errors.lastname ||
                            errors.email ||
                            errors.telnum ||
                            errors.message ||
                            !this.state.touched.firstname ||
                            !this.state.touched.lastname ||
                            !this.state.touched.email ||
                            !this.state.touched.telnum ||
                            !this.state.touched.message
                            ? "btn btn-danger"
                            : "btn btn-success"
                        }
                        disabled={
                          errors.firstname ||
                            errors.lastname ||
                            errors.email ||
                            errors.telnum ||
                            errors.message ||
                            !this.state.touched.firstname ||
                            !this.state.touched.lastname ||
                            !this.state.touched.email ||
                            !this.state.touched.telnum ||
                            !this.state.touched.message
                            ? true
                            : false
                        }
                      >
                        Send
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {this.state.toastMessage &&
          <Toast
            toastList={[this.state.toastMessage]}
            position="bottom-right" />
        }
      </>
    );
  }
}
