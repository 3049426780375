import logo from './logo.png';
import { banner } from './Banner';
import { team } from './Team';
import { whyus } from './Why Us';
import { portfolio } from './Portfolio';
import { icons } from './Icons';
import techLogo from './techieralogorbg.png';
import svgLogo from './techieralogorbg.svg';
import laptop from "./laptop.png";
import centerLaptop from "./centerLaptop.png";
import laptopsvg from "./laptopsvg.svg";


const images = {
    ...banner,
    ...team,
    ...whyus,
    ...icons,
    ...portfolio,
    logo,
    techLogo,
    svgLogo,
    laptop,
    laptopsvg,
    centerLaptop
};
export default images;