import React, { useState, useEffect } from 'react';

import './Toast.scss';
import * as bootstrap from 'bootstrap';


const Toast = (props: { toastList: any, position: any }) => {
    //ToDo:Refactor code using classes
    const { toastList, position } = props;
    const [list, setList] = useState(toastList);

    useEffect(() => {
        setList(toastList);
        if (toastList) {
            const toastLiveExample = document.getElementById('liveToast');

            if (toastLiveExample) {
                const toast = new bootstrap.Toast(toastLiveExample);
                toast.show();
            }
        }
    }, [toastList, list]);

    return (
        <>
            <div className={`notification-container ${position}`}>
                {
                    list.map((toast: { backgroundColor: any; icon: string | undefined; title: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; description: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; }, i: React.Key | null | undefined) => {
                        return (<div
                            key={i}
                            className={`notification toast ${position}`}
                            style={toast ? { backgroundColor: toast.backgroundColor } : {}}
                            role="alert"
                            data-bs-autohide="true"
                            id='liveToast'
                        >
                            <div className="toast-header">
                                <div className="notification-image">
                                    {toast && <img src={toast.icon} alt="" />}
                                </div>
                                {toast && <p className="notification-title">{toast.title}</p>}
                                <button type="button" className='btn close' data-bs-dismiss="toast" aria-label="Close">
                                    X
                                </button>
                            </div>
                            <div className="toast-body">
                                {toast && <p className="notification-message">
                                    {toast.description}
                                </p>
                                }
                            </div>
                        </div>
                        );
                    }
                    )
                }
            </div>
        </>
    );
}
export default Toast;